<template>
  <div id="cashResult">
    <div class="result-box">
      <img v-lazy="dataInfo.pic" alt />
      <p class="title">{{dataInfo.title}}</p>
      <p class="tips-main">{{dataInfo.tipsMain}}</p>
      <p class="tips">{{dataInfo.tips}}</p>
      <div class="btn-box">
        <div class="btn" @click="$router.replace({path: '/index'})">返回首页</div>
        <div class="btn active" @click="$router.replace({path: '/cashOut'})">继续提现</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      dataInfo: {
        pic: require("@images/success.png"),
        title: "申请成功",
        tipsMain: "您的提现申请已经提交",
        tips: "提现金额将会在1~3个工作日内转至您的账户"
      },
      failInfo: {
        pic: require("@images/fail.png"),
        title: "申请失败",
        tipsMain: "余额不足",
        tips: "请确认您是否已在其他端申请提现"
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    getInfo() {
      if (this.$route.query.type == 1) {
        this.dataInfo = {
          pic: require("@images/success.png"),
          title: "申请成功",
          tipsMain: "您的提现申请已经提交",
          tips: "提现金额将会在1~3个工作日内转至您的账户"
        };
      } else {
        this.dataInfo = {
          pic: require("@images/fail.png"),
          title: "申请失败",
          tipsMain: "余额不足",
          tips: "请确认您是否已在其他端申请提现"
        };
      }
    }
  }
};
</script>
<style lang="scss" >
 @import '../common/css/theme.scss';
#cashResult {
  min-height: 100vh;
  background:rgba(245,247,250,1);
  .result-box {
    height: 56* 3.75px;
    padding-top: 6.4* 3.75px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    img {
      height: 16* 3.75px;
      width: 16* 3.75px;
      margin: 0 auto 1.6* 3.75px;
      display: block;
    }

    .title {
      font-size: 3.73* 3.75px;
      color: rgba(96, 98, 102, 1);
      text-align: center;
    }
    .tips-main {
      text-align: center;
      margin-bottom: 1.06* 3.75px;
      color: rgba(144, 147, 153, 1);
      font-size: 3.2* 3.75px;
    }

    .tips {
      text-align: center;
      color: rgba(144, 147, 153, 1);
      font-size: 3.2* 3.75px;
      margin-bottom: 3.2* 3.75px;
    }
    .btn-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn {
      width: 29.33* 3.75px;
      text-align: center;
      height: 8* 3.75px;
      border: 1px solid $theme-color;
      opacity: 1;
      border-radius: 1* 3.75px;
      font-size: 3.73* 3.75px;
      line-height: 8* 3.75px;
      color: $theme-color;
      box-sizing: border-box;
      &:first-child {
        margin-right: 1.6* 3.75px;
      }
      &.active {
        background: $theme-color;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>